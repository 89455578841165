import React from 'react';
import { NamedLink } from '../../../..';
import classNames from 'classnames';

import {
  isConflictingItems,
  VIEW_ALL_KEY,
} from '../../../TopbarDesktop/TopbarDropdownMenu/TopbarDropdownMenu.helpers';
import css from './MenuAccordionSubItem.module.css';

// This component renders the sub-item in the accordion menu
const AccordionSubItemNav = ({ item, toggleActiveSubItem, hasOptions, conflictingItems }) => {
  if (hasOptions) {
    return (
      <div className={css.accordionSubItemNav} onClick={() => toggleActiveSubItem(item.key)}>
        <span className={css.subItemLabel}>{item.label}</span>
        <span className={css.plusIcon} />
      </div>
    );
  }

  return (
    <NamedLink
      className={css.accordionSubItemNav}
      name="SearchPage"
      to={{
        search: conflictingItems
          ? `pub_category=${item.category}`
          : `pub_category=${item.category}&pub_collection=${item.key}`,
      }}
    >
      <span className={css.subItemLabel}>{item.label}</span>
    </NamedLink>
  );
};

// This component renders accordion sub-item link
const AccordionSubItemLink = ({ item, subItem, conflictingSubItems }) => {
  return (
    <NamedLink
      className={css.accordionSubItemLink}
      key={subItem.key}
      name="SearchPage"
      to={{
        search: conflictingSubItems
          ? `pub_category=${item.category}&pub_collection=${subItem.collection}`
          : `pub_category=${item.category}&pub_subcategory=${subItem.key}`,
      }}
    >
      {subItem.label}
    </NamedLink>
  );
};

const MenuAccordionSubItem = props => {
  const { item, activeSubItemKey, setActiveSubItemKey } = props;
  const isActiveSubItemKey = activeSubItemKey === item.key;
  const hasOptions =
    item.options?.length > 0 &&
    !(item.options.length === 1 && item.options.find(o => o.key === VIEW_ALL_KEY));
  const conflictingItems = isConflictingItems(item.key);
  const subMenuClasses = classNames(css.accordionSubItem, {
    [css.activeAccordionSubItem]: isActiveSubItemKey,
  });

  const toggleActiveSubItem = key => {
    if (isActiveSubItemKey) {
      setActiveSubItemKey(null);
    } else {
      setActiveSubItemKey(key);
    }
  };

  const subItems = isActiveSubItemKey
    ? item.options.map(subItem => (
        <AccordionSubItemLink
          key={subItem.key}
          item={item}
          subItem={subItem}
          conflictingSubItems={isConflictingItems(subItem.key)}
        />
      ))
    : null;

  return (
    <div className={subMenuClasses}>
      <AccordionSubItemNav
        item={item}
        toggleActiveSubItem={toggleActiveSubItem}
        hasOptions={hasOptions}
        conflictingItems={conflictingItems}
      />
      {subItems}
    </div>
  );
};

export default MenuAccordionSubItem;
